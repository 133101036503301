import React, { FC } from 'react';
import { CourseCardImage } from 'src/common/components/CourseCard/CourseCardImage';
import {
  CourseCardContent,
  FeaturedButtonType,
} from 'src/common/components/CourseCard/CourseCardContent';
import { CourseCardDatePanel } from 'src/common/components/CourseCard/CourseCardDatePanel';
import styled, { css } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import SmallCtaIcon from 'src/assets/icons/SmallCtaIcon';
import { CourseCardStyledProps } from 'src/common/components/CourseCard/config';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import MedalBadgeIcon from 'src/assets/icons/MedalBadgeIcon';
import { layers } from 'src/config/layers';

export const Box = styled.div`
  background: ${colors.white};
  box-shadow: ${shadows.drop1};
  border-radius: 0.375rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  padding: 2.1875rem 1.25rem 1.25rem;
  width: 100%;

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      ${!theme.featuredBreakpoint &&
      css`
        flex-direction: row;
        padding: 1.3125rem 0.9375rem 1.875rem 1.375rem;
      `}

      ${theme.featuredBreakpoint &&
      css`
        padding: 0.625rem 0.75rem 1.25rem;

        ${media.w.min[theme.featuredBreakpoint](css`
          flex-direction: row;
          padding: 1.3125rem 0.9375rem 1.875rem 1.375rem;
        `)}
      `}
    `}

  ${({ theme }: CourseCardStyledProps) =>
    theme.largeMobileImage &&
    css`
      ${media.w.max.px576(css`
        padding: 1.25rem;
      `)};
    `}
`;

export const StyledSmallCtaIcon = styled(SmallCtaIcon)`
  bottom: 0.625rem;
  color: ${colors.red};
  opacity: 0;
  position: absolute;
  right: 0.625rem;
  transition: opacity 0.2s ease;
`;

const StyledMedalBadgeIcon = styled(MedalBadgeIcon)`
  position: absolute;
  top: 0.6875rem;
  left: 0.525rem;
  z-index: ${layers.contentBottom};
`;

export interface CourseCardBoxProps {
  featured?: boolean;
  featuredBreakpoint?: keyof typeof widthBreakpoints;
  featuredButton?: FeaturedButtonType;
  courseExcerpt: CourseExcerpt;
}

export const CourseCardBox: FC<CourseCardBoxProps> = ({
  featured,
  featuredBreakpoint,
  featuredButton,
  courseExcerpt,
}) => (
  <Box>
    {courseExcerpt.progressStatus?.isCompleted && <StyledMedalBadgeIcon size={38} />}
    <CourseCardDatePanel courseExcerpt={courseExcerpt} />

    <CourseCardImage image={courseExcerpt.image} />
    <CourseCardContent
      featured={featured}
      featuredBreakpoint={featuredBreakpoint}
      featuredButton={featuredButton}
      courseExcerpt={courseExcerpt}
    />
    <StyledSmallCtaIcon size={18} />
  </Box>
);
