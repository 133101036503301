import React, { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { layers } from 'src/config/layers';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { Duration } from 'src/common/components/Duration';

const Container = styled.div`
  background: ${colors.darkGrey08};
  border-radius: 0.25rem;
  padding: 0.1875rem 0.4375rem;
  position: absolute;
  right: 0.625rem;
  top: 0.6875rem;
  z-index: ${layers.contentBottom};
`;

const StyledDuration = styled(Duration)`
  color: ${colors.blue};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 300;
  & > .duration-value {
    color: ${colors.red};
    font-family: ${typography.fontFamilyHeaders};
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export interface ElearningDatePanelProps {
  courseExcerpt: CourseExcerpt;
}

export const ElearningCourseDatePanel: FC<ElearningDatePanelProps> = ({ courseExcerpt }) =>
  !courseExcerpt.duration ? null : (
    <Container>
      <StyledDuration duration={courseExcerpt.duration} />
    </Container>
  );
