import React, { FC } from 'react';
import { format } from 'src/lib/date';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { typography } from 'src/config/typography';
import { CourseCardStyledProps } from 'src/common/components/CourseCard/config';
import { layers } from 'src/config/layers';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';

const Container = styled.div`
  background: ${colors.darkGrey};
  border-radius: 0.25rem;
  padding: 0.25rem 0.3125rem 0.5rem;
  position: absolute;
  right: 0.625rem;
  top: 0.6875rem;
  z-index: ${layers.contentBottom};

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      right: 0.9375rem;
      top: 1.1875rem;

      ${media.w.max.px992(css`
        align-items: center;
        background: ${colors.white};
        display: flex;
        padding: 0.3125rem 0.6875rem 0.3125rem 0.3125rem;
        right: 0.8125rem;
        top: 1.125rem;
      `)};
    `}
`;

const Date = styled.div`
  align-items: center;
  background: ${colors.white};
  border-radius: 0.25rem;
  color: ${colors.blue};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: 300;
  height: 3.75rem;
  justify-content: center;
  line-height: 1.25rem;
  text-transform: uppercase;
  width: 3.75rem;

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      ${media.w.max.px992(css`
        background: ${colors.darkGrey};
        flex-direction: row;
        height: 2rem;
        margin-right: 0.625rem;
        width: 4.6875rem;
      `)};
    `}
  span {
    color: ${colors.pfp3};
    font-family: ${typography.fontFamilyHeaders};
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;

    ${({ theme }: CourseCardStyledProps) =>
      theme.featured &&
      css`
        ${media.w.max.px992(css`
          color: ${colors.blue};
          margin-right: 0.25rem;
        `)};
      `}
  }
`;

const Time = styled.div`
  color: ${colors.blue};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1rem;
  text-align: center;
  margin-top: 0.375rem;

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      ${media.w.max.px992(css`
        margin-top: 0;
      `)};
    `}
`;

export interface OnlineCourseDatePanelProps {
  courseExcerpt: CourseExcerpt;
}

export const OnlineCourseDatePanel: FC<OnlineCourseDatePanelProps> = ({ courseExcerpt }) => {
  if (!courseExcerpt.date) {
    return null;
  }
  return (
    <Container>
      <Date>
        <span>{format(courseExcerpt.date, 'dd')}</span>
        {format(courseExcerpt.date, 'MMM')}
      </Date>

      <Time>{format(courseExcerpt.date, 'HH:mm')}</Time>
    </Container>
  );
};
