import { Speaker } from 'src/common/models/speaker';
import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import { ListWrapper } from 'src/lib/listWrapper';

export const speakerFullName = (speaker: Pick<Speaker, 'firstName' | 'lastName'>) =>
  `${speaker.firstName} ${speaker.lastName}`;

export const getRandomSpeakers = (limit: number) =>
  BaseAxios.get<ListWrapper<Speaker>>(`/api/speakers/random`, {
    params: {
      limit,
    },
  })
    .then(flatten)
    .catch(throwErrorResponse);
