import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { CourseCardStyledProps } from 'src/common/components/CourseCard/config';
import { StoredImageDTO } from 'src/common/models/image';
import Image from 'next/image';

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  grid-area: image;
  height: 9.375rem;
  width: 100%;
  position: relative;

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      ${!theme.featuredBreakpoint &&
      css`
        height: auto;
        order: 2;
        width: 48%;
      `}

      ${theme.featuredBreakpoint &&
      media.w.min[theme.featuredBreakpoint](css`
        height: auto;
        order: 2;
        width: 48%;
      `)}
    `}

  ${({ theme }: CourseCardStyledProps) =>
    theme.largeMobileImage &&
    css`
      ${media.w.max.px576(css`
        height: 13rem;
      `)};
    `}
  
  img {
    object-fit: contain;

    // todo: check if necessary; it makes the ImageContainer's "align-items: center" useless
    object-position: left bottom;

    ${({ theme }: CourseCardStyledProps) =>
      theme.featured &&
      css`
        object-position: ${theme.featuredBreakpoint ? 'center' : 'top right'};

        ${theme.featuredBreakpoint &&
        media.w.min[theme.featuredBreakpoint](css`
          object-position: top right;
        `)};
      `}
  }
`;

const ImageFeaturedWrapper = styled.div`
  display: ${({ theme }: CourseCardStyledProps) => (theme.featured ? 'block' : 'none')};

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    theme.featuredBreakpoint &&
    media.w.max[theme.featuredBreakpoint](css`
      display: none;
    `)}
`;

const ImageDefaultWrapper = styled.div`
  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      display: ${theme.featuredBreakpoint ? 'block' : 'none'};

      ${theme.featuredBreakpoint &&
      media.w.min[theme.featuredBreakpoint](css`
        display: none;
      `)};
    `}
`;

export interface CourseCardImageProps {
  image?: StoredImageDTO;
}

export const CourseCardImage: FC<CourseCardImageProps> = ({ image }) => (
  <ImageContainer>
    {image && (
      <>
        <ImageDefaultWrapper>
          <Image src={image.url} layout="fill" />
        </ImageDefaultWrapper>
        <ImageFeaturedWrapper>
          <Image src={image.url} layout="fill" />
        </ImageFeaturedWrapper>
      </>
    )}
  </ImageContainer>
);
