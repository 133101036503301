import React, { FC } from 'react';
import { ElearningCourseDatePanel } from 'src/common/components/CourseCard/CourseCardDatePanel/ElearningCourseDatePanel';
import { OnlineCourseDatePanel } from 'src/common/components/CourseCard/CourseCardDatePanel/OnlineCourseDatePanel';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { CourseType } from 'src/common/models/courseType';

export interface CourseCardDatePanelProps {
  courseExcerpt: CourseExcerpt;
}

export const CourseCardDatePanel: FC<CourseCardDatePanelProps> = ({ courseExcerpt }) => {
  return courseExcerpt.courseType === CourseType.ElearningCourse ? (
    <ElearningCourseDatePanel courseExcerpt={courseExcerpt} />
  ) : (
    <OnlineCourseDatePanel courseExcerpt={courseExcerpt} />
  );
};
