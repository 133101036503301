import React, { FC } from 'react';
import { Markdown } from 'src/common/components/Markdown';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { textOverflow, textOverflowLines } from 'src/common/styles/typography';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { useMediaQuery } from 'src/common/migration/useMediaQuery';
import {
  CourseCardStyledProps,
  generateDetailsLink,
  generateSurveyLink,
} from 'src/common/components/CourseCard/config';
import { speakerFullName } from 'src/common/services/speaker';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { CourseCardLabel } from 'src/common/components/CourseCard/CourseCardLabel';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { CourseCardContentTitle } from 'src/common/components/CourseCard/CourseCardContentTitle';
import { CourseType } from 'src/common/models/courseType';

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-align: left;

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      margin-top: 1.5rem;

      ${!theme.featuredBreakpoint &&
      css`
        margin-top: 0;
        order: 1;
        width: 52%;
      `}

      ${theme.featuredBreakpoint &&
      media.w.min[theme.featuredBreakpoint](css`
        margin-top: 0;
        order: 1;
        width: 52%;
      `)}
    `}
`;

const SpeakerCompany = styled.div`
  color: ${colors.blue};
  font-size: 0.625rem;
  line-height: 0.875rem;

  ${textOverflow(1)}
`;

const SpeakerName = styled.div`
  color: ${colors.blue};
  font-weight: 700;

  ${textOverflow(1)}
`;

const SpeakerText = styled.div`
  color: ${colors.blue};
  margin: 0.625rem 0 1.25rem;

  ${textOverflow(3)}

  ${media.w.min.px992(css`
    margin: 1.25rem 0;
    max-width: 18.75rem;

    ${textOverflowLines(4)}
  `)};
`;

const SpeakerInfoContainer = styled.div`
  margin-top: auto;

  ${({ theme }: CourseCardStyledProps) =>
    theme.elearningProgressBar &&
    css`
      display: none;
    `}
`;

const ProgressBar = styled.div<{ percentage: number }>`
  position: relative;
  height: 0.3125rem;
  width: 100%;
  border-radius: 0.125rem;
  background: ${colors.darkGrey};

  &::after {
    position: absolute;
    border-radius: 0.125rem;
    content: '';
    background: ${colors.red};
    height: 5px;
    left: 0;
    top: 0;
    width: ${({ percentage }) => percentage + '%'};
  }
`;

const ProgressContainer = styled.div`
  display: none;

  ${({ theme }: CourseCardStyledProps) =>
    theme.elearningProgressBar &&
    css`
      display: flex;
    `}

  line-height: 1rem;
  flex-direction: column;
  gap: 0.375rem;

  span {
    font-family: ${typography.fontFamilyDefault};
    font-size: 0.75rem;
    color: ${colors.blue};
  }
`;

const StyledButtonPrimary = styled(Button)`
  grid-area: button;
  width: 100%;

  ${({ theme }: CourseCardStyledProps) =>
    !theme.featured &&
    media.w.min.px992(css`
      justify-self: start;
      width: auto;
    `)}

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      ${!theme.featuredBreakpoint &&
      css`
        justify-self: start;
        width: auto;
      `}

      ${theme.featuredBreakpoint &&
      media.w.min[theme.featuredBreakpoint](css`
        justify-self: start;
        width: auto;
      `)}
    `}
`;

const RedeemCertificateButton = styled(Button)`
  margin-top: 0.5625rem;
`;

export type FeaturedButtonType = 'primary' | 'secondary' | 'none';

export interface CourseCardContentProps {
  featured?: boolean;
  featuredBreakpoint?: keyof typeof widthBreakpoints;
  featuredButton?: FeaturedButtonType;
  courseExcerpt: CourseExcerpt;
}

export const CourseCardContent: FC<CourseCardContentProps> = ({
  featured,
  featuredBreakpoint,
  featuredButton,
  courseExcerpt,
}) => {
  const isMobile = useMediaQuery({
    maxWidth: featuredBreakpoint ? below(widthBreakpoints[featuredBreakpoint]) : 0,
  });

  return (
    <Content>
      <CourseCardContentTitle>
        <Markdown text={courseExcerpt.title} />
      </CourseCardContentTitle>
      <CourseCardLabel featured={featured} type={courseExcerpt.courseType} />

      {courseExcerpt.speaker != null && courseExcerpt.courseType !== CourseType.CourseBundle && (
        <SpeakerInfoContainer>
          <SpeakerName>{speakerFullName(courseExcerpt.speaker)}</SpeakerName>
          <SpeakerCompany>{courseExcerpt.speaker.companyName}</SpeakerCompany>
        </SpeakerInfoContainer>
      )}

      {courseExcerpt.progressStatus && (
        <ProgressContainer>
          <span>Obejrzałeś {courseExcerpt.progressStatus.percentageWatched}%</span>
          <ProgressBar percentage={courseExcerpt.progressStatus.percentageWatched} />
        </ProgressContainer>
      )}

      {courseExcerpt.progressStatus?.isCompleted &&
        !courseExcerpt.progressStatus?.certificateDownloaded && (
          <RedeemCertificateButton
            as={GatsbyLink}
            kind="primary"
            size="tiny"
            to={generateSurveyLink(courseExcerpt)}
          >
            Odbierz certyfikat
          </RedeemCertificateButton>
        )}

      {featured && (
        <>
          <SpeakerText>
            {courseExcerpt.comment && <Markdown text={courseExcerpt.comment} />}
          </SpeakerText>

          {featuredButton === 'none' ? null : (
            <StyledButtonPrimary
              as={GatsbyLink}
              size={isMobile ? 'small' : 'default'}
              kind={featuredButton}
              to={generateDetailsLink(courseExcerpt)}
            >
              {featuredButton === 'primary' ? 'Zapisz się bezpłatnie' : 'Rozpocznij szkolenie'}
            </StyledButtonPrimary>
          )}
        </>
      )}
    </Content>
  );
};
