import React, { FC } from 'react';
import LiveStreamingIcon from 'src/assets/icons/LiveStreamingIcon';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { StyleProps } from 'src/lib/styleProps';
import { courseCardTypeLabelNameMapper } from 'src/common/components/CourseCard/config';
import { CourseType } from 'src/common/models/courseType';

const LabelBox = styled.div<{ type: CourseType }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3125rem;
  overflow: hidden;

  ${({ type = CourseType.StandardCourse }) =>
    type &&
    css`
      margin: 0.625rem 0;
    `}
`;

const LiveLabel = styled.div`
  align-items: center;
  background: ${colors.red};
  border-radius: 0.25rem;
  color: ${colors.white};
  display: flex;
  flex: 0 0 auto;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  height: 1.5rem;
  line-height: 0.875rem;
  padding: 0.3125rem;

  span {
    margin-left: 0.3125rem;
  }
`;

const TrainingLabel = styled.div`
  align-items: center;
  border: 1px solid ${colors.pfp3bis};
  border-radius: 0.25rem;
  display: flex;
  flex: 0 0 auto;
  font-size: 0.625rem;
  height: 1.5rem;
  line-height: 0.875rem;
  padding: 0.3125rem;

  span {
    color: ${colors.pfp3bis};
    font-family: ${typography.fontFamilyDefault};
  }
`;

export interface CourseCardLabelProps {
  type: CourseType;
  featured?: boolean;
}

export const CourseCardLabel: FC<CourseCardLabelProps & StyleProps> = ({
  type,
  className,
  style,
  featured,
}) => {
  if (featured) {
    return null;
  }

  return (
    <LabelBox type={type} className={className} style={style}>
      <TrainingLabel>
        <span>{courseCardTypeLabelNameMapper[type]}</span>
      </TrainingLabel>

      {type === CourseType.StandardCourse && (
        <LiveLabel>
          <LiveStreamingIcon size={14} />
          <span>Na żywo</span>
        </LiveLabel>
      )}
    </LabelBox>
  );
};
