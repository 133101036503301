import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { textOverflow } from 'src/common/styles/typography';
import { CourseCardStyledProps } from 'src/common/components/CourseCard/config';
import { media } from 'src/config/breakpoints';

export const CourseCardContentTitle = styled.div`
  max-width: 100%;
  color: ${colors.blue};
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  line-height: 1.375rem;
  overflow: hidden;
  padding-left: 2px;

  ${textOverflow(3)}

  & * {
    text-overflow: ellipsis !important;
  }

  ${({ theme }: CourseCardStyledProps) =>
    !theme.featured &&
    css`
      //todo: change to auto height, remember to fit list grid row height !
      height: 4.125rem;
      margin-top: 1rem;
    `}

  ${({ theme }: CourseCardStyledProps) =>
    theme.featured &&
    css`
      margin-bottom: 1.25rem;
      //todo: change to auto height, remember to fit list grid row height !
      height: 4.125rem;
      padding-left: 2px;

      ${!theme.featuredBreakpoint &&
      css`
        display: block;
        font-size: 1.5rem;
        line-height: 1.875rem;
        height: unset;
      `}

      ${theme.featuredBreakpoint &&
      media.w.min[theme.featuredBreakpoint](css`
        display: block;
        font-size: 1.5rem;
        line-height: 1.875rem;
        height: unset;
      `)}
    `}
`;
