import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const SmallCtaIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M1.5621 1.5621c2.08279-2.0828 5.45967-2.0828 7.54247 0L62.4379 54.8954c2.0828 2.0828 2.0828 5.4597 0 7.5425-2.0828 2.0828-5.4597 2.0828-7.5425 0L1.5621 9.10457c-2.0828-2.0828-2.0828-5.45968 0-7.54247z"
    />
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M58.6667 0C61.6122 0 64 2.38781 64 5.33333V58.6667C64 61.6122 61.6122 64 58.6667 64H5.33333C2.38781 64 0 61.6122 0 58.6667c0-2.9456 2.38781-5.3334 5.33333-5.3334H53.3333V5.33333C53.3333 2.38781 55.7211 0 58.6667 0z"
    />
  </IconBox>
);

export default SmallCtaIcon;
